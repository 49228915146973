import { Link } from "@/lib/i18n/b2b-link"
import { cn } from "@/lib/utils"
import { Dispatch, MouseEvent, ReactElement, SetStateAction } from "react"
import { checkIfActive, hrefFor } from "./resolve-links"
import { NavigationItemWithPathname } from "./types"

interface DesktopNavProps {
  currentDomain: string
  items: NavigationItemWithPathname[]
  onNavClick: (sectionId: string) => void
  isContactOpen: boolean
  setIsContactOpen: Dispatch<SetStateAction<boolean>>
  currentPath: string
}

const DesktopNav = ({ setIsContactOpen, currentPath, currentDomain, items }: DesktopNavProps): ReactElement => {
  if (!currentDomain) {
    throw new Error("The property 'currentDomain' is required")
  }

  const handleDemoClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    setIsContactOpen(true)
  }

  return (
    <>
      <div className="hidden lg:flex items-center gap-4">
        {items.map((item: NavigationItemWithPathname): ReactElement | null => {
          const href = hrefFor(item)
          if (!href || typeof href !== 'string') {
            return null
          }
          const isActive = checkIfActive(currentPath, href)

          return (
            <Link
              key={item.label}
              href={href}
              currentDomain={currentDomain}
              className={cn(
                "text-neutral-900 transition-colors font-medium px-4 py-1.5 relative",
                {
                  "hover:text-primary": !isActive,
                  "text-accent-purple hover:text-accent-purple after:absolute after:inset-0 after:bg-accent-purple/10 after:rounded-full after:-z-10": isActive
                }
              )}
            >
              {item.label}
            </Link>)
        })}
      </div>

      <div className="hidden lg:flex items-center gap-4 whitespace-nowrap">
        <button
          onClick={handleDemoClick}
          type="button"
          className={cn(
            "px-4 text-primary hover:text-primary/80 transition-colors font-medium"
          )}
        >
          Arrange a demo
        </button>
        <a
          href="https://minimist.app.link/Bq1JPddiqNb"
          target="_blank"
          rel="noopener noreferrer"
          className="button-primary"
        >
          Download the app
        </a>
      </div>
    </>
  )
}

export default DesktopNav