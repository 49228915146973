import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { Textarea } from "@/components/ui/textarea"

interface ContactMessageFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any
}

const ContactMessageField = ({ form }: ContactMessageFieldProps) => {
  return (
    <FormField
      control={form.control}
      name="message"
      render={({ field }) => (
        <FormItem className="space-y-2">
          <FormLabel htmlFor="message">Message (optional)</FormLabel>
          <FormControl>
            <Textarea
              id="message"
              className="glass-panel min-h-[100px]"
              {...field}
            />
          </FormControl>
        </FormItem>
      )}
    />
  )
}

export default ContactMessageField