import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { Input } from "@/components/ui/input"

interface ContactEmailFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any
}

const ContactEmailField = ({ form }: ContactEmailFieldProps) => {
  return (
    <FormField
      control={form.control}
      name="email"
      rules={{
        required: "Please enter your email",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Invalid email address"
        }
      }}
      render={({ field }) => (
        <FormItem className="space-y-2">
          <FormLabel htmlFor="email">What&apos;s your e-mail?</FormLabel>
          <FormControl>
            <Input
              id="email"
              type="email"
              className="glass-panel"
              {...field}
            />
          </FormControl>
        </FormItem>
      )}
    />
  )
}

export default ContactEmailField