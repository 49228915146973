export type SupportedLocale = 'en' | 'sv' | 'de' | 'nl'

export const domainToLocale: Record<string, SupportedLocale[]> = {
  'getminimist.se': ['sv', 'en'],
  'minimist.at': ['de', 'en'],
  'minimist.de': ['de', 'en'],
  'minimist.nl': ['nl', 'en'],
}

/**
 * Supported locales are the locales that are supported by the app.
 * They are the first part of the locale (e.g. en, sv, de).
 */
export const supportedLocales = new Set<SupportedLocale>(Object.values(domainToLocale).flatMap(locale => locale))

/**
 * Supported locales for the B2B website.
 */
export const b2bLocales: readonly SupportedLocale[] = ['en', 'sv', 'de', 'nl']

/**
 * Fallback locale is the locale that is used if the user's locale is not supported.
 */
export const fallbackLocale: SupportedLocale = 'en'
