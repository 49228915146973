"use client"

import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog"
import { DEBUG } from "@/lib/env"
import { Link as B2BLink } from "@/lib/i18n/b2b-link"
import { cn } from "@/lib/utils"
import { Menu } from "lucide-react"
import Image from "next/image"
import { usePathname } from "next/navigation"
import { useEffect, useState } from "react"
import logo from '../../../public/uploads/logo.png'
import ContactForm from "../forms/contact-form"
import DesktopNav from "./desktop-nav"
import MobileMenu from "./mobile-menu"
import { NavigationItemWithPathname } from "./types"
import { useSalesBanner } from "@/contexts/sales-banner-context"

interface NavigationProps {
  className?: string
  logoClassName?: string
  menuClassName?: string
  currentDomain: string
  items: NavigationItemWithPathname[]
}

declare global {
  interface WindowEventMap {
    openContactForm: CustomEvent<void>
  }
}

const isCustomEvent = (event: Event): event is CustomEvent<void> => {
  return 'detail' in event && event instanceof CustomEvent
}

const Navigation = ({
  className,
  logoClassName,
  menuClassName,
  currentDomain,
  items = []
}: NavigationProps): React.ReactNode => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isContactOpen, setIsContactOpen] = useState<boolean>(false)
  const pathname = usePathname()
  const { isVisible: isSalesBannerVisible } = useSalesBanner()

  useEffect(() => {
    const handleOpenContactForm = (event: Event) => {
      if (!isCustomEvent(event)) {
        return
      }
      setIsContactOpen(true)
    }

    window.addEventListener('openContactForm', handleOpenContactForm)
    return () => {
      window.removeEventListener('openContactForm', handleOpenContactForm)
    }
  }, [])

  const scrollToSection = (sectionId: string): void => {
    const element = document.getElementById(sectionId)
    if (!element) {
      return
    }
    element.scrollIntoView({ behavior: 'smooth' })
  }

  if (DEBUG) {
    console.log(`[navigation] currentDomain=${currentDomain} items=${items.length}`)
  }

  return (
    <>
      <nav className={cn("fixed top-4 left-1/2 -translate-x-1/2 w-[95%] max-w-screen-xl z-40", className, {
        'pt-10': isSalesBannerVisible
      })}>
        <div className="glass-panel rounded-full px-6 py-4 flex items-center justify-between shadow-lg backdrop-blur-md">
          <B2BLink href="/" currentDomain={currentDomain} className="flex items-center gap-2 hover:opacity-80 transition-opacity">
            <Image
              src={logo}
              alt="minimist"
              height={40}
              className={cn("h-10", logoClassName)}
              quality={90}
            />
          </B2BLink>

          <DesktopNav
            currentDomain={currentDomain}
            items={items}
            onNavClick={scrollToSection}
            isContactOpen={isContactOpen}
            setIsContactOpen={setIsContactOpen}
            currentPath={pathname}
          />

          <button
            className="lg:hidden p-2 hover:bg-neutral-200/50 rounded-full transition-colors"
            onClick={(): void => { setIsMenuOpen(!isMenuOpen) }}
          >
            <Menu className={cn("w-6 h-6", menuClassName)} />
          </button>
        </div>

        <MobileMenu
          isOpen={isMenuOpen}
          onClose={() => { setIsMenuOpen(false) }}
          items={items}
          onNavClick={scrollToSection}
          isContactOpen={isContactOpen}
          setIsContactOpen={setIsContactOpen}
          currentPath={pathname}
          currentDomain={currentDomain}
        />
      </nav>

      <Dialog open={isContactOpen} onOpenChange={setIsContactOpen}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogTitle className="text-xl font-semibold mb-4">
            Contact Us
          </DialogTitle>
          <ContactForm onClose={() => { setIsContactOpen(false) }} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Navigation