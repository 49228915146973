"use client"
import { getClientAPI } from "@/app/api/client"
import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { useToast } from "@/components/ui/use-toast"
import confetti from "canvas-confetti"
import { Loader2 } from "lucide-react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import ContactEmailField from "./form-fields/ContactEmailField"
import ContactMessageField from "./form-fields/ContactMessageField"
import ContactNameField from "./form-fields/ContactNameField"
import ContactStoreField from "./form-fields/ContactStoreField"

interface ContactFormData {
  storeName: string;
  email: string;
  name: string;
  message?: string;
}

interface ContactFormProps {
  onClose: () => void;
}

const ContactForm = ({ onClose }: ContactFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { toast } = useToast()
  const form = useForm<ContactFormData>()

  const onSubmit = async (data: ContactFormData) => {
    try {
      setIsSubmitting(true)
      const api = getClientAPI()

      await api.prospects.contactRequests.create({
        store_name: data.storeName,
        email: data.email,
        name: data.name,
        message: data.message || "",
      })

      toast({
        title: "Thanks for getting in touch!",
        description: "We'll get back to you shortly.",
      })

      // Trigger confetti
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      })

      form.reset()
      onClose()
    } catch (error) {
      console.error('Failed to send message:', error)
      toast({
        title: "Error",
        description: "Failed to send message. Please try again.",
        variant: "destructive",
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-6 py-4"
      >
        <ContactStoreField form={form} />
        <ContactEmailField form={form} />
        <ContactNameField form={form} />
        <ContactMessageField form={form} />

        <Button
          type="submit"
          className="w-full button-primary"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Getting in touch...
            </>
          ) : (
            "Get in touch"
          )}
        </Button>
      </form>
    </Form>
  )
}

export default ContactForm