"use client"

import { Link } from "@/lib/i18n/b2b-link"
import { cn } from "@/lib/utils"
import { useLocale } from "next-intl"
import { usePathname, useSearchParams } from "next/navigation"
import { useState } from "react"

const locales = [{
  locale: "en",
  flag: "🇬🇧",
  name: "English"
}, {
  locale: "de",
  flag: "🇩🇪",
  name: "Deutsch"
}, {
  locale: "sv",
  flag: "🇸🇪",
  name: "Svenska"
}, {
  locale: "nl",
  flag: "🇳🇱",
  name: "Nederlands"
}]


interface LocaleSwitcherProps {
  className?: string
  currentDomain: string
}

export default function LocaleSwitcher({
  className,
  currentDomain
}: LocaleSwitcherProps) {
  const [isOpen, setIsOpen] = useState(false)
  const locale = useLocale()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  return (
    <div className={cn("relative inline-block", className)}>
      <div
        className={cn("absolute bottom-full mb-1 bg-white border border-neutral-200 rounded-md shadow-lg transition-all duration-300 whitespace-nowrap", isOpen ? "visible opacity-100" : "hidden opacity-0")}
        onMouseLeave={() => { setIsOpen(false) }}
      >
        {locales.map((lng) => {
          const cleanedPathname = pathname.replace(/^\/\w{2}(\/|$)/, '/')
          const href = `${cleanedPathname}${searchParams.toString()}`
          return (
            <Link
              key={lng.locale}
              href={href}
              currentDomain={currentDomain}
              className="block px-3 py-2 text-sm text-neutral-600 hover:bg-neutral-100 hover:text-primary transition-colors"
              locale={lng.locale}
            >
              {lng.flag} {lng.name}
            </Link>
          )
        })}
      </div>
      <div
        onMouseEnter={() => { setIsOpen(true) }}
        className="px-3 py-2 text-sm text-neutral-600 border border-neutral-200 rounded-md focus:outline-none focus:ring-2 focus:ring-primary flex items-center gap-2 cursor-pointer"
      >
        <span>{locales.find(l => l.locale === locale)?.flag ?? locales[0].flag}</span>
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
    </div>
  )
}