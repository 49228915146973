"use client"


interface FooterCopyrightProps {
  copyright: string;
}

export function FooterCopyright({ copyright }: FooterCopyrightProps) {
  return (
    <div className="border-t border-neutral-200 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center gap-4">
      <p className="text-sm text-neutral-600">
        {copyright}
      </p>
    </div>
  )
} 