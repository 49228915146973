'use client'

import { createContext, useState, useContext, useEffect } from 'react'

interface SalesBannerContextType {
  isVisible: boolean
  setIsVisible: (value: boolean) => void
  hideBanner: () => void
}

const SalesBannerContext = createContext<SalesBannerContextType | undefined>(undefined)

export function SalesBannerProvider({ 
  children, 
  initialVisible = true,
  expiryDate
}: { 
  children: React.ReactNode
  initialVisible?: boolean
  expiryDate?: string
}) {
  const [isVisible, setIsVisible] = useState(initialVisible)
  
  useEffect(() => {
    // Check expiry date
    if (expiryDate) {
      const expiry = new Date(expiryDate)
      const now = new Date()
      if (now > expiry) {
        setIsVisible(false)
      }
    }
    
    // Check if user previously closed the banner
    const bannerHidden = localStorage.getItem('salesBannerHidden')
    if (bannerHidden === 'true') {
      setIsVisible(false)
    }
  }, [expiryDate])
  
  const hideBanner = () => {
    setIsVisible(false)
    localStorage.setItem('salesBannerHidden', 'true')
  }
  
  return (
    <SalesBannerContext.Provider value={{ isVisible, setIsVisible, hideBanner }}>
      {children}
    </SalesBannerContext.Provider>
  )
}

export function useSalesBanner() {
  const context = useContext(SalesBannerContext)
  if (context === undefined) {
    throw new Error('useSalesBanner must be used within a SalesBannerProvider')
  }
  return context
} 