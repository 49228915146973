import { defineQuery } from "next-sanity"
/**
 * From https://www.sanity.io/docs/presenting-images#6f93276ab029
 * https://www.sanity.io/docs/image-type#df3f768ce073
 */
const imageFields = /* groq */ `
  ...,
  "asset": asset-> {
    altText,
    title,
    description,
    url,
    path,
    assetId,
    extension,
    "tags": opt.media.tags[]->name.current,
    metadata {
      lqip,
      dimensions {
        width,
        height
      }
    }
  }
`

const postFields = /* groq */ `
  _id,
  _type,
  "status": select(_originalId in path("drafts.**") => "draft", "published"),
  title,
  "slug": slug.current,
  ingress,
  excerpt,
  coverImage {${imageFields}},
  "date": coalesce(date, _updatedAt),
  "author": author->{"name": coalesce(name, "Anonymous"), picture},
  relatedPosts[]{
    _key, // required for drag and drop
    ...@->{
      _id, 
      title, 
      "slug": slug.current,
      excerpt,
      coverImage {${imageFields}},
      date
    } // get fields from the referenced post
  }
`

// single post
export const postQuery = defineQuery(/* groq */ `
  *[_type == "post" && slug.current == $slug][0] {
    content[] {
      ...,
      markDefs[] {
        ...,
        _type == "internalLink" => {
          ...,
          "slug": @.reference->slug.current
        }
      }
    },
    ${postFields}
  }
`)

// list of posts
export const postsQuery = defineQuery(/* groq */ `
  *[
    _type == "post" && 
    _id > $lastId && 
    defined(slug.current) && 
    locale == $locale &&
    !("press-releases" in category[]->slug.current)
  ] | order(date desc, _updatedAt desc) [0...$limit] {
    ${postFields}
  }
`)
export const postsCountQuery = defineQuery(/* groq */ `
  count(*[
    _type == "post" && 
    defined(slug.current) &&
    locale == $locale &&
    !("press-releases" in category[]->slug.current)
  ])
`)

// single press release (in any locale)

export const pressReleaseQuery = defineQuery(/* groq */ `
  *[
    _type == "post" && 
    slug.current == $slug && 
    ("press-releases" in category[]->slug.current)
  ] [0] {
    content,
    ${postFields}
  }
`)

// list of press releases — in any locale
export const pressReleasesQuery = defineQuery(/* groq */ `
  *[
    _type == "post" && 
    defined(slug.current) && 
    ("press-releases" in category[]->slug.current)
  ] | order(date desc, _updatedAt desc) [$start...$end] {
    _id,
    title,
    date,
    "slug": slug.current,
    ingress
  }
`)

export const pressReleasesCountQuery = defineQuery(/* groq */ `
  count(*[
    _type == "post" && 
    defined(slug.current) &&
    ("press-releases" in category[]->slug.current)
  ])
`)

export const pressReleaseBySlugQuery = defineQuery(/* groq */ `
  *[_type == "post" && slug.current == $slug && ("press-releases" in category[]->slug.current)][0] {
    _id,
    title,
    date,
    "slug": slug.current,
    ingress,
    content
  }
`)

// Base fields that all sections share
const sectionFields = /* groq */ `
  _type,
  _key,
  sectionId,
  navigationLabel,
`

// Section-specific fragments
const heroSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const heroVideoSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const heroContentSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const contentSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const trustedBySectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const featuresSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const aboutSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const pricingSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const ctaSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const statsSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const gallerySectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const twoColumnSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const mapSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const pressSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const textWithIllustrationSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const pageHeaderSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const spacerSectionFields = /* groq */ `
  ${sectionFields}
  ...
`

const pageBuilderSections = /* groq */ `
  _type == "heroSection" => {
    ${heroSectionFields}
  },
  _type == "heroVideoSection" => {
    ${heroVideoSectionFields}
  },
  _type == "heroContentSection" => {
    ${heroContentSectionFields}
  },
  _type == "contentSection" => {
    ${contentSectionFields}
  },
  _type == "trustedBySection" => {
    ${trustedBySectionFields}
  },
  _type == "featuresSection" => {
    ${featuresSectionFields}
  },
  _type == "aboutSection" => {
    ${aboutSectionFields}
  },
  _type == "pricingSection" => {
    ${pricingSectionFields}
  },
  _type == "ctaSection" => {
    ${ctaSectionFields}
  },
  _type == "statsSection" => {
    ${statsSectionFields}
  },
  _type == "gallerySection" => {
    ${gallerySectionFields}
  },
  _type == "twoColumnSection" => {
    ${twoColumnSectionFields}
  },
  _type == "mapSection" => {
    ${mapSectionFields}
  },
  _type == "pressSection" => {
    ${pressSectionFields}
  },
  _type == "textWithIllustrationSection" => {
    ${textWithIllustrationSectionFields}
  },
  _type == "pageHeaderSection" => {
    ${pageHeaderSectionFields}
  },
  _type == "spacerSection" => {
    ${spacerSectionFields}
  }
`

// Main page query using all section fragments
export const b2bPageQuery = defineQuery(/* groq */ `
  *[
    _type == "page"
    && domain == "b2b"
    && locale == $locale
    && slug.current == $slug
  ][0] {
    ...,
    pageBuilder[]{
      ${sectionFields}
      ${pageBuilderSections}
    },
    seo {
      ...
    }
  }
`)

const navFields = /* groq */ `
  _type,
  _id,
  locale,
  "slug": slug.current,
`

/**
 * For `internalLink` in navigation items.
 */
const hierarchicalNavFields = /* groq */ `
  _type,
  _id,
  _ref,
  locale,
  "slug": slug.current,
  parent->{
    _type,
    _id,
    _ref,
    locale,
    "slug": slug.current,
  }
`

export const pageNavQuery = defineQuery(/* groq */ `
  *[_type == "page" && _id == $id][0] {
    ${hierarchicalNavFields}
  }
`)

export const categoryNavQuery = defineQuery(/* groq */ `
  *[_type == "category" && _id == $id][0] {
    ${hierarchicalNavFields}
  }
`)

export const postNavQuery = defineQuery(/* groq */ `
  *[_type == "post" && _id == $id][0] {
    ${navFields}
  }
`)

export const linkFields = /* groq */ `
  _key,
  "label": coalesce(
    label[_key == $locale][0].value,
    label[_key == "en"][0].value
  ),
  type,
  internalLink->{
    _type == "category" => {
      ${hierarchicalNavFields}
    },
    _type == "page" => {
      ${hierarchicalNavFields}
    },
    _type == "post" => {
      ${navFields}
    }
  },
  externalUrl,
  eventName,
  "isVisible": coalesce(
    isVisible[_key == $locale][0].value,
    isVisible[_key == "en"][0].value,
    true
  )
`

export const settingsQuery = defineQuery(`*[_type == "settings"][0] {
  ...,
  salesBanner {
    ...,
    link {
      ${linkFields}
    }
  }
}`)


const layoutNavigationFields = /* groq */ `
  navigation[]{
    ${linkFields}
  },
`

const internalLinkFields = /* groq */ `
  _type == "category" => {
    ${hierarchicalNavFields}
  },
  _type == "page" => {
    ${hierarchicalNavFields}
  },
  _type == "post" => {
    ${navFields}
  },
`

const layoutFooterLinkListFields = /* groq */ `
  links[]{
    _key,
    "label": coalesce(
      label[_key == $locale][0].value,
      label[_key == "en"][0].value
    ),
    type,
    internalLink->{
      ${internalLinkFields}
    },
    externalUrl,
    eventName,
    "isVisible": coalesce(
      isVisible[_key == $locale][0].value,
      isVisible[_key == "en"][0].value,
      true
    ),
  },
`

const layoutFooterRichTextFields = /* groq */ `
  ...,
  markDefs[] {
    ...,
    _type == "internalLink" => @->{
      ${internalLinkFields}
    }
  }
`

// TODO: Add rich text fields and figure out how to properly query them
// _type == "richText" =>{
//   ${layoutFooterRichTextFields}
// },

const layoutFooterFields = /* groq */ `
  footer {
    columns[] {
      _key,
      "title": coalesce(
        title[_key == $locale][0].value,
        title[_key == "en"][0].value
      ),
      colSpan,
      content[] {
        _type,
        _key,
        _type == "linkList" => {
          ${layoutFooterLinkListFields}
        },
        _type == "image" => {
          ${imageFields}
        },
        _type == "richText" => {
          ${layoutFooterRichTextFields}
        },
      }
    },
    "copyright": coalesce(
      copyright[_key == $locale][0].value,
      copyright[_key == "en"][0].value,
      "Copyright © 2024 Minimist"
    )
  }
`

// At the top of the file, add the shared theme fields constant
const themeLayoutFields = /* groq */ `
  layout {
    name,
    showSectionNav,
    startContentAtTop,
    ${layoutNavigationFields}
    showFooter,
    ${layoutFooterFields}
  },
`


// Update the theme queries to use the shared fields
export const themeQuery = defineQuery(`
  *[_type == "theme" && _id == $themeId][0] {
    _id,
    ${themeLayoutFields}
  }
`)

export const defaultThemeQuery = defineQuery(`
  *[_type == "settings"][0] {
    defaultTheme-> {
      ${themeLayoutFields}
    },
    stagingTheme-> {
      ${themeLayoutFields}
    }
  }
`)
