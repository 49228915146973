'use client'
import { useSalesBanner } from "@/contexts/sales-banner-context"
import { DEBUG } from "@/lib/env"
import { cn } from "@/lib/utils"
import { useEffect, useState } from "react"

interface Section {
  id: string
  label: string
}

interface SectionNavProps {
  sections: Section[]
  className?: string
  containerClassName?: string
  buttonClassName?: string
}

const SectionNav = ({
  sections,
  className,
  containerClassName,
  buttonClassName
}: SectionNavProps) => {
  const [activeSection, setActiveSection] = useState<string>("")
  const [intersectingSection, setIntersectingSection] = useState<string>("")
  const { isVisible: isSalesBannerVisible } = useSalesBanner()

  // Intersection Observer for rough detection
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIntersectingSection(entry.target.id)
          }
        })
      },
      {
        threshold: [0, 0.1, 0.5],
        rootMargin: "-20% 0px"
      }
    )

    sections.forEach(({ id }) => {
      const element = document.getElementById(id)
      if (element) observer.observe(element)
    })

    return () => {
      observer.disconnect()
    }
  }, [sections])

  // Scroll handler for precise detection
  useEffect(() => {
    const handleScroll = () => {
      if (!intersectingSection) return

      const scrollPosition = window.scrollY + window.innerHeight * 0.3
      const element = document.getElementById(intersectingSection)

      if (element) {
        const rect = element.getBoundingClientRect()
        const absoluteTop = window.scrollY + rect.top
        const absoluteBottom = absoluteTop + rect.height

        if (scrollPosition >= absoluteTop && scrollPosition < absoluteBottom) {
          setActiveSection(intersectingSection)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [intersectingSection])

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  if (sections.length === 0) return null

  if (DEBUG) console.log(`[section-nav] sections=${sections.length} activeSection=${activeSection} intersectingSection=${intersectingSection}`)

  return (
    <div className={cn(
      "fixed top-24 left-1/2 -translate-x-1/2 z-40 animate-fade-in hidden md:block",
      className, {
        'pt-10': isSalesBannerVisible
      }
    )}>
      <div className={cn(
        "glass-panel rounded-full px-6 py-3 shadow-lg backdrop-blur-md flex items-center gap-6",
        containerClassName
      )}>
        {sections.map(({ id, label }) => (
          <button
            key={id}
            onClick={() => { scrollToSection(id) }}
            className={cn(
              "text-sm font-medium transition-colors duration-300 whitespace-nowrap",
              activeSection === id ? "text-primary" : "text-neutral-500 hover:text-neutral-700",
              buttonClassName
            )}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  )
}

export default SectionNav