'use client'

import { cn } from "@/lib/utils"
import { X } from "lucide-react"
import { Link as B2BLink } from "@/lib/i18n/b2b-link"
import { useSalesBanner } from "@/contexts/sales-banner-context"

const colorStyles = {
  red: "bg-red-600 text-white",
  blue: "bg-blue-600 text-white",
  green: "bg-green-600 text-white",
  yellow: "bg-yellow-600 text-white",
  black: "bg-black text-white",
}

export interface SalesBannerProps {
  text: string
  linkText?: string
  linkHref?: string
  color?: 'red' | 'blue' | 'green' | 'yellow' | 'black'
  expiryDate?: string
  currentDomain: string
}

export default function SalesBanner({ text, linkHref, linkText, color = 'red', currentDomain }: SalesBannerProps) {
  const { isVisible, hideBanner } = useSalesBanner()
  
  if (!isVisible) return null
  
  return (
    <div className={cn("fixed top-0 left-0 w-full z-50 py-2 text-center text-sm font-medium", colorStyles[color])}>
      <div className="container mx-auto px-4 relative">
        <div className="flex flex-col sm:flex-row items-center justify-center gap-2 pr-8">
          {linkHref ? (
            <B2BLink
              href={linkHref}
              currentDomain={currentDomain}
              className="w-full cursor-pointer hover:opacity-90 transition-opacity"
            >
              <div className="flex flex-col sm:flex-row items-center justify-center gap-2">
                <span>{text}</span>
                {linkText && (
                  <span className="underline font-semibold">
                    {linkText}
                  </span>
                )}
              </div>
            </B2BLink>
          ) : (
            <>
              <span>{text}</span>
              {linkText && (
                <span className="underline font-semibold">
                  {linkText}
                </span>
              )}
            </>
          )}
        </div>
        <button 
          onClick={hideBanner}
          className="absolute right-4 top-1/2 -translate-y-1/2 p-1 hover:opacity-80 transition-opacity z-10"
          aria-label="Close banner"
        >
          <X size={16} />
        </button>
      </div>
    </div>
  )
} 