import { ROOT_DOMAIN } from "@/lib/env"
import { Link as IntlLink } from "@/lib/i18n/routing-b2b"
import { useLocale } from "next-intl"

type LinkProps = Parameters<typeof IntlLink>[0] & {
  currentDomain: string
}

export const Link = ({ children, locale: inputLocale, currentDomain, ...props }: LinkProps) => {
  if (props.href == null) {
    throw new Error(`href is null; children: ${JSON.stringify(children)}`)
  }

  const nextIntlLocale = useLocale()
  const locale = inputLocale ?? nextIntlLocale
  const href = props.href.toString()

  // console.log('locale', locale, 'href', href)

  // Return early if it's not a relative path or if it's already on ROOT_DOMAIN
  if (!href.startsWith('/') || currentDomain === "localhost" || currentDomain === ROOT_DOMAIN || currentDomain === 'localhost:3000') {
    return <IntlLink {...props} locale={locale}>{children}</IntlLink>
  }

  const hrefPath = href.startsWith('/') ? href.slice(1) : href
  const newHref = `https://${[ROOT_DOMAIN, locale, hrefPath].filter(Boolean).join('/')}`

  // console.log('domain', currentDomain, 'newHref', newHref)
  return <IntlLink {...props} href={newHref} locale={locale}>{children}</IntlLink>
}
