'use client'

import { Component, type PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  name: string
  fallback?: React.ReactNode
}

interface State {
  hasError: boolean
  error: Error | null
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error) {
    console.error(`Error in ${this.props.name}:`, error)
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback
      }

      // Default error UI
      if (process.env.NODE_ENV === 'development') {
        return (
          <div className="p-4 my-4 bg-red-50 border border-red-200 rounded-lg">
            <h3 className="text-red-800 font-semibold mb-2">
              Error in {this.props.name}
            </h3>
            <pre className="text-sm text-red-700 whitespace-pre-wrap">
              {this.state.error?.message}
            </pre>
          </div>
        )
      }
      // In production, we might want to show a minimal error or custom fallback
      return null
    }

    return this.props.children
  }
} 