import { client } from "@/sanity/lib/client"
import { categoryNavQuery, pageNavQuery } from "@/sanity/lib/queries"
import { token } from '@/sanity/lib/token'
import { InternalLink, NavigationItem, NavigationItemWithPathname } from "./types"

function isInternalLink(link: unknown): link is InternalLink {
  if (!link || typeof link !== 'object') return false
  const typedLink = link as { _type: string; _id?: string; slug?: string }
  return (
    typeof typedLink._type === 'string' &&
    (typedLink._id === undefined || typeof typedLink._id === 'string') &&
    (typedLink.slug === undefined || typeof typedLink.slug === 'string')
  )
}

export async function resolveSlugs(
  id: string,
  query: string,
  type: 'page' | 'category',
  isDraftMode = false
): Promise<string> {
  const previewClient = isDraftMode
    ? client.withConfig({
      token,
      useCdn: false,
      perspective: 'previewDrafts'
    })
    : client

  const result = await previewClient.fetch<{ slug: string }>(query, { id })
  if (!result.slug) {
    throw new Error(`${type} ${id} not found or missing slug`)
  }
  return result.slug
}

// Wrapper functions to maintain the same API
export async function resolveSlugsForPage(pageId: string, isDraftMode = false): Promise<string> {
  return resolveSlugs(pageId, pageNavQuery, 'page', isDraftMode)
}

export async function resolveSlugsForCategory(categoryId: string, isDraftMode = false): Promise<string> {
  return resolveSlugs(categoryId, categoryNavQuery, 'category', isDraftMode)
}

/**
 * Returns the expanded pathname for a given link
 */
export async function resolveLink(link: InternalLink, isDraftMode = false): Promise<string> {
  if (!isInternalLink(link)) {
    throw new Error(`Invalid internal link provided: ${JSON.stringify(link)}`)
  }

  switch (link._type) {
    case 'page': {
      if (!link._id) throw new Error('Page link missing _id')
      return resolveSlugsForPage(link._id, isDraftMode)
    }
    case 'category': {
      if (!link._id) throw new Error('Category link missing _id')
      return resolveSlugsForCategory(link._id, isDraftMode)
    }
    case 'post': {
      if (!link.slug) throw new Error('Post link missing slug')
      return link.slug
    }
  }
}

/**
 * Resolves the pathname for each link in the navigation data structure
 */
export default async function resolveLinks(
  items: NavigationItem[],
  isDraftMode = false
): Promise<NavigationItemWithPathname[]> {
  if (!Array.isArray(items)) {
    return []
  }

  return Promise.all(
    items.map(async (item): Promise<NavigationItemWithPathname> => {
      if (item.type === 'internal' && item.internalLink) {
        const pathname = await resolveLink(item.internalLink, isDraftMode)
        return {
          ...item,
          internalLink: {
            ...item.internalLink,
            pathname
          }
        }
      }
      return item as NavigationItemWithPathname
    })
  )
}

export const checkIfActive = (currentPath: string, path: string): boolean => {
  const normalizedPath = currentPath.replace(/^\/(\w{2})/, '')
  return normalizedPath === path || (normalizedPath === '' && path === '/')
}

export const hrefFor = (item: NavigationItemWithPathname): string => {
  if (item.type === 'internal' && item.internalLink?.pathname) {
    return item.internalLink.pathname
  }
  if (item.type === 'external' && item.externalUrl) {
    return item.externalUrl
  }
  throw new Error(`Invalid navigation item: ${JSON.stringify(item)}`)
}