'use client'

interface EventButtonProps {
  label: string;
  eventName: string;
}

export const EventButton = ({ label, eventName }: EventButtonProps) => {
  return (
    <button
      onClick={() => {
        const event = new CustomEvent(eventName)
        window.dispatchEvent(event)
      }}
      className="hover:text-primary transition-colors"
    >
      {label}
    </button>
  )
} 