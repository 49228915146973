import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { Input } from "@/components/ui/input"

interface ContactStoreFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any
}

const ContactStoreField = ({ form }: ContactStoreFieldProps) => {
  return (
    <FormField
      control={form.control}
      name="storeName"
      rules={{ required: "Please enter your store name" }}
      render={({ field }) => (
        <FormItem className="space-y-2">
          <FormLabel htmlFor="storeName">What&apos s your store called?</FormLabel>
          <FormControl>
            <Input
              id="storeName"
              className="glass-panel"
              {...field}
            />
          </FormControl>
        </FormItem>
      )}
    />
  )
}

export default ContactStoreField