"use client"

import { Link } from "@/lib/i18n/b2b-link"
import { cn } from "@/lib/utils"
import { motion } from "framer-motion"
import React, { Dispatch, SetStateAction } from 'react'
import { checkIfActive, hrefFor } from "./resolve-links"
import { NavigationItemWithPathname } from "./types"

interface MobileMenuProps {
  isOpen: boolean
  onClose: () => void
  items: NavigationItemWithPathname[]
  onNavClick: (sectionId: string) => void
  isContactOpen: boolean
  setIsContactOpen: Dispatch<SetStateAction<boolean>>
  currentPath: string
  currentDomain: string
}

const MobileMenu = ({
  isOpen,
  onClose,
  items,
  setIsContactOpen,
  currentPath,
  currentDomain
}: MobileMenuProps): React.ReactElement => {
  if (!currentDomain) {
    throw new Error("The property 'currentDomain' is required")
  }

  const handleContactClick = (): void => {
    setIsContactOpen(true)
    onClose()
  }

  return (
    <motion.div
      className="lg:hidden glass-panel mt-2 rounded-xl p-4 shadow-lg"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      role="dialog"
      aria-modal="true"
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className="flex flex-col gap-4">
        {items.map((item) => {
          const href = hrefFor(item)
          const isActive = checkIfActive(currentPath, href)
          return (
            <Link
              key={item.label}
              href={href}
              currentDomain={currentDomain}
              className={cn(
                'text-neutral-600 hover:text-primary transition-colors font-medium px-4 py-2 hover:bg-neutral-200/50 rounded-lg',
                { 'bg-accent-purple/10 text-accent-purple': isActive }
              )}
              onClick={onClose}
            >
              {item.label}
            </Link>
          )
        })}

        <hr className="border-neutral-200" />

        <button
          onClick={handleContactClick}
          className="text-primary hover:text-primary/80 transition-colors font-medium px-4 py-2 hover:bg-neutral-200/50 rounded-lg text-left"
          type="button"
        >
          Arrange a demo
        </button>
        <a
          href="https://minimist.app.link/Bq1JPddiqNb"
          target="_blank"
          rel="noopener noreferrer"
          className="button-primary w-full text-center"
        >
          Download the app
        </a>
      </div>
    </motion.div>
  )
}

export default MobileMenu