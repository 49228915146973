import { SANITY_API_VERSION, SANITY_DATASET, SANITY_PROJECT_ID, SANITY_STUDIO_PATHNAME } from "@/lib/env"
import imageUrlBuilder from '@sanity/image-url'
import { SanityImageSource } from "@sanity/image-url/lib/types/types"
import { createClient } from "next-sanity"

export const client = createClient({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET,
  apiVersion: SANITY_API_VERSION,
  useCdn: true,
  stega: {
    studioUrl: SANITY_STUDIO_PATHNAME,
    logger: console,
    filter: (props) => {
      if (props.sourcePath.at(-1) === "title") {
        return true
      }

      return props.filterDefault(props)
    },
  },
})


const builder = imageUrlBuilder(client)


/**
 * Image URL builder
 * 
 * Docs: https://www.sanity.io/docs/presenting-images
 * Docs: https://sanity-io-land.slack.com/archives/C07CSTHU8EM/p1734543118558379
 * 
 * @param source - The image source
 * @returns The image URL
 */
export const urlFor = (source: SanityImageSource) => {
  return builder.image(source)
}